import React from "react";
import { Col, Row } from "react-bootstrap";
import { Phone1, Phone2, Google, Apple } from "../../../assets/images/index";
import Container from "react-bootstrap/Container";
// import { Card, Col, Container, Row } from "react-bootstrap";

const WhatIsBorga = () => {
  return (
    <section className="about-session text-white what-is-borga-section ">
      <Container>
        <div className="section-text-header fw-bold text-black">
          What is <span className="text-info">Borga?</span>
        </div>
      </Container>
      <div className="what-is-borga-body">
        <Container>
          <Row className="what-is-borga-row-bg  gx-4">
            <Col
              md={12}
              lg={5}
              className="what-is-borga-col order-md-last order-lg-first "
            >
              <div className="text-black fw-light fs-6 body-text">
                <p className="content-text">
                  Borga is the ultimate remittance app created exclusively for
                  individuals in the UK to effortlessly pay for services back
                  home in Africa.
                </p>

                <p className="content-text">
                  It is convenient and secure platform for users to make
                  payments and send money from the UK to Africa, whether it's
                  for bills, supporting businesses or providing financial
                  assistance of families and friends.
                </p>

              </div>

              <div className="d-flex">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=money.borga&hl=en_US&pli=1">
                    <img
                      src={Google}
                      className="brand-logo"
                      alt="googleplay"
                      style={{ width: "200px" }}
                    />
                  </a>
                </div>
                <div>
                  <a href="https://apps.apple.com/us/app/borga-money/id6463721868">
                    <img
                      src={Apple}
                      className="mx-2 brand-logo"
                      style={{ width: "200px" }}
                      alt="appstore"
                    />
                  </a>
                </div>
              </div>

              {/* <div className="d-flex">
                  <div className="google-play-logo mt-3"></div>
                  <div className="app-store-logo mx-3 mt-3"></div>
                </div> */}
            </Col>
            {/* <Col
                md={6}
                lg={6}
                className="order-md-first  order-lg-last custom-md-col"
              >
                <div className="d-flex position-absolute phone-container justify-content-end">
                  <div className="first-phone">
                    <img src={Phone1} alt="phone" className="style-for-phone-image" />
                  </div>
                  <div className="second-phone mx-4">
                    <img src={Phone2} alt="phone" className="style-for-phone-image" />
                  </div>
                </div>
              </Col> */}
            <Col md={6} lg={6}>
              <div className="d-flex mobile">
                <div className="phone-display">
                  <img src={Phone1} className="phone-1" alt="phone" />
                </div>
                <div className="phone-display">
                  <img src={Phone2} className="phone-2" alt="phone" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default WhatIsBorga;

import React from "react";
import {  Row, Col, Image } from "react-bootstrap";
import Container from 'react-bootstrap/Container';

import {
  ICO,
  Logo,
  Mastercard,
  TrustPayment,
  Visa,
} from "../../../assets/images/index";
import { BiLogoFacebook } from "react-icons/bi";
import { RiLinkedinLine } from "react-icons/ri";
import { CiTwitter } from "react-icons/ci";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  const Logos = [
    { image: ICO ,
      width: 68,
      height:59 ,
    },
    { image: TrustPayment ,
      width:141,
      height:50
    },
    { image: Visa,
      width:86,
      height:26
     },
    { image: Mastercard ,
      width:62,
      height:47
    },
    
  ];
  return (
    <footer className="border-top bg-primary">
      <Container className="py-5">
        <Row className="align-items-center footer-logo-section pb-4 gy-5">
          <Col className="text-start footer-col " md={12} lg={3} xl={3}>
            <Image className="d-inline mb-3 me-3" src={Logo} width="90" />
          </Col>
          <Col className="text-start footer-col " md={12} lg={6} xl={6}>
            <div className="d-flex footer-menu justify-content-between ">
              <div className="mx-3">
                <Link to="/faqs#contact-us" className="text-white ">
                  Contact us
                </Link>
              </div>
              <div className="mx-3">
                <Link to="/faqs#faqs" className="text-white ">
                  FAQs
                </Link>
              </div>
              <div className="mx-3">
                <Link to="/privacy#privacy" className="text-white ">
                  Privacy
                </Link>
              </div>
              <div>
                <Link to="/terms-conditions#ts-cs" className="text-white">
                  Ts & Cs
                </Link>
              </div>
            </div>
          </Col>
          <Col className="text-start px-5 footer-col" md={12} lg={3} xl={3}>
            <div className="d-flex footer-icons-container">
              <div className="border-for-icons rounded-2 mx-2">
                <a href="https://www.linkedin.com/company/borga-money/about/">
                  <RiLinkedinLine className="text-white fs-5 mx-2" />
                </a>
              </div>
              <div className="border-for-icons rounded-2 mx-2">
                <a href="https://www.facebook.com/BorgaMoney">
                  <BiLogoFacebook className="text-white fs-5 mx-2" />
                </a>
              </div>
              <div className="border-for-icons py-1 rounded-2 mx-2">
                <a href="https://twitter.com/BorgaMoney">
                  <CiTwitter className="text-white fs-5 mx-2" />
                </a>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-3 text-center">
          <Col>
            <div className="text-white footer-text fw-light p-5">
              DIAMOND-CUBE LIMITED trading as Borga Money is registered with
              Companies House with company No. 12110351. We are registered as a
              Money Service Business and supervised by HM Revenue & Customs
              (HMRC) under Money Laundering Regulations (MLR)
              No.XXML00000152530. We are Authorised and Regulated by the
              Financial Conduct Authority (FCA) as a payment institution with
              reference number 901063.
            </div>
          </Col>
        </Row>
        <div className="d-flex flex-wrap justify-content-center align-items-center">
          {Logos.map((data, key) => (
            <div key={key} className="mx-3">
              <img src={data.image} alt="logos" width={data.width} height={data.height}/>
            </div>
          ))}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

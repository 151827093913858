import Container from 'react-bootstrap/Container';
export const Privacy = () => {
  return (
    <Container className="privacy-section-container">
      <div className="text-center section-text-header py-5" id="privacy">
        Privacy Policy
      </div>
      <div className="mb-5">
        <p className="point-header  fw-bolder fs-5">Borga Money Application</p>
        <p className="fw-light">
          Borga Money App (“us”, “we” or “our”) is an international remittance
          application (the “App”) which provides fund transfer services, direct
          merchant payment services and direct service payment services (‘the
          Services’).We are committed to protecting and respecting Your
          privacy and handling Your information in an open and transparent
          manner.This policy{" "}
          <span className="fw-bold">("Privacy Policy")</span> explains the
          nature of the information we collect from You or which You provide to
          us, the sharing of Your information and the usage and processing of
          Your information. The Privacy Policy also sets out Your rights with
          regards to Your information. Please read this Privacy Policy carefully
          in order to understand our practices regarding Your personal data and
          how we will treat it. By using the App, you acknowledge and agree to
          the collection and use of information in accordance with this Privacy
          Policy or the latest revised version of this Policy.In this Privacy
          Policy, Your information may sometimes be called "personal data". We
          may also sometimes collectively refer to handling, collecting,
          protecting and storing Your personal data as "processing" such
          personal data.In this Privacy Policy, the term "Data Protection
          Legislation" means the EU General Data Protection Regulation 2016/679;
          together with all other applicable legislation relating to privacy or
          data protection. 
        </p>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Nature of Personal Data Collected </p>
        <p className="fw-light">
          We will collect, record and use information about You in electronic
          form and will hold, use and otherwise process the data in accordance
          with the Data Protection Legislation and as set out in this Privacy
          Policy.  The personal data we collect and process may include, among
          others: 
        </p>
        <ul>
          <li>
            Your contact information including Your telephone number and email
            address; 
          </li>
          <li>Unique identifiers such as username, account number and PIN; </li>
          <li>
            Your payment details or other financial data (e.g. Your bank or
            payment method provider’s name, your account number) 
          </li>
          <li>
            if you are a Merchant, we will collect information about your
            business including but not limited to registration documents,
            licenses and the products you intend to offer to sell on the App;
            and  We also collect from You information in relation to other
            people (e.g. details of the recipients of Your fund transfers,
            purchases and/or payments on the App), where You provide us with
            such information. By providing us with such information, you confirm
            that you have obtained any necessary permissions from such persons
            to the reasonable use of their information for such purposes in
            accordance with this notice, or are otherwise permitted to give us
            this information on their behalf. You also confirm that those other
            people are aware of this notice and that the provisions of this
            notice are clearly communicated to them. 
          </li>
        </ul>
      </div>

      <div>
        <p  className="  point-header fw-bolder fs-5">Mode of Collection of Personal Data </p>
        <p className="fw-light">
          When You download the App, in addition to the information mentioned
          above we: 
        </p>
        <ul>
          <li>
            automatically collect information on the type of device You use,
            operating system version, and system and performance information. To
            ensure You receive proper notifications, we will need to collect
            certain information about Your device such as operating system and
            user identification information; 
          </li>
          <li>
            {" "}
            may collect Your location-based information for the purpose of
            verifying your eligibility to register an account on the App; 
          </li>
          <li>
            Your payment details or other financial data (e.g. Your bank or
            payment method provider’s name, your account number) 
          </li>
        </ul>
      </div>
      <div>
        <p  className=" point-header fw-bolder fs-5">Usage of Personal Data </p>
        <p className="fw-light">
          Our primary purpose in collecting user information is to provide the
          services You have requested from us and to provide You with an
          efficient experience. We may also use the information that You have
          provided to ensure that the content on our site is presented in the
          most effective manner for You and Your computer or device.  We use
          Your information for the following specific purposes: 
        </p>
        <ul>
          <li>to verify your identity on the App; </li>
          <li>to send You confirmations; </li>
          <li>to verify transactions; </li>
          <li>to notify You about changes to our Services; </li>
          <li>
            to prevent fraud, money laundering, cyber-attacks and other security
            incidents; 
          </li>
          <li>
            to administer our App and for internal operations, including
            troubleshooting, data analysis, testing, research, statistical and
            survey purposes; {" "}
          </li>
          <li>
            for other purposes which are not inconsistent with this Policy or
            any other law. 
          </li>
        </ul>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Legal Basis For Usage of Personal Data  </p>
        <p className="fw-light">
          We are permitted by law to process Your personal data in the manner
          which we do because the processing is: 
        </p>
        <ul>
          <li>
            {" "}
            necessary for:
            <ul>
              <li>the provision of our Services; </li>
              <li>
                the prevention of fraud, cyber-attacks, money laundering and
                like security incidents; 
              </li>
              <li>
                the assessment, development and improvement of our Services; {" "}
              </li>
              <li>
                the fulfilment of our legal obligations to store and disclose
                information where necessary;  {" "}
              </li>
              <li>the provision of an efficient customer experience; </li>
              <li>compliance with law; and/or </li>
            </ul>
             
          </li>
          <li> authorised by You, as You have given us Your consent.  </li>
        </ul>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Sharing of Personal Data  </p>
        <p className="fw-light">
          The following categories of recipients may receive Your personal
          information and process it for the purposes outlined in this Privacy
          Policy: 
        </p>
        <ul>
          <li>
            third party companies contracted to provide services to or for us
            pursuant to our Agreement with You for the use of the App;   
          </li>
          <li>
            other business entities, should we plan to merge with, or be
            acquired by, or be invested in by that business entity, or if we
            undergo a corporate re-organisation;  
          </li>
          <li>any successor in interest of Borga Money, where applicable;  </li>
          <li> to any other third party, subject to Your prior consent;</li>
          <li>
            {" "}
            fraud prevention agencies who may use it to prevent fraud and
            money-laundering and to verify Your identity; 
          </li>
          <li>
            {" "}
            judicial or regulatory authorities where required under applicable
            law. 
          </li>
        </ul>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Protection of Personal Data  </p>
        <p className="fw-light">
          We will implement measures that serve as safeguards to protect Your
          information from unauthorised access. These measures include accepted
          industry standards such as encryption that protect Your personal
          information.  All transmissions, particularly across the internet are
          however not foolproof. In event that we come into knowledge of any
          unauthorized access to your Personal information, we will notify you
          as soon as is reasonably possible.   While we will use our best
          endeavours to secure your Personal information transmitted over the
          internet, we do not guarantee the total security of your Personal
          information transmitted over the internet. Once we have received Your
          information, we will employ our best efforts, procedures and security
          features to try to prevent unauthorised access. 
        </p>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Storage of Personal Data  </p>
        <p className="fw-light">
          We will only store Your information for as long as is required for the
          purposes set out above, or as required to comply with any legal
          obligations to which we are subject. We may store Your data for the
          purposes of sending communications in accordance with this Privacy
          Policy given that You do not unsubscribe from receiving the same from
          us. 
        </p>
      </div>
      <div>
        <p  className=" point-header fw-bolder fs-5">Transferring of Personal Data   </p>
        <p className="fw-light">
          Your information is stored and controlled by us. Your information may
          be transferred to, or stored by a third party outside the UK and may
          be processed by staff operating outside the UK who work for us, or for
          one of our agents or contractors.   We ensure the protection of Your
          information where such information is to be processed outside the UK
          by the execution of binding agreements and the application of
          corporate rules in accordance with applicable Data Protection
          Legislation.  Where necessary or required by law, we may request to
          rely on Your consent to process Your personal data to outside the UK.
          This will however not be the norm. Where we do request this consent,
          You may withdraw Your consent for such processing. 
        </p>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Communications</p>
        <p className="fw-light">
          You will have the option of opting in or out of receiving marketing
          communications.  You may, at any point in time upon opting in,
          unsubscribe from our mailing list for marketing communications, alerts
          and updates being sent to You. Details of how to unsubscribe will be
          included on each electronic mailing we send You.  
        </p>
      </div>
      <div>
        <p  className=" point-header fw-bolder fs-5">Your Rights </p>
        <p className="fw-light">You reserve the right to: </p>
        <ul>
          <li>
            be notified about the processing of Your personal data. This Privacy
            Policy satisfies Your right to be informed; 
          </li>
          <li>
            request access to, or a copy of any personal data which we hold
            about You; 
          </li>
          <li>
            {" "}
            correct Your personal data, where You are of the opinion that such
            personal data is incorrect or outdated;   
          </li>
          <li>
            {" "}
            request that Your personal data is deleted, where You are of the
            opinion that we lack the authority to store it; 
          </li>

          <li>
            revoke consent to our processing of Your personal data, which may in
            certain cases mean a termination of our agreement with You for the
            provision of our Services; 
          </li>
        </ul>
        <p>
          You may contact us <a href="mailto:mailto:support@borga.money" className="">support@borga.money</a> to enforce any of these
          rights. We will employ our best efforts to respond within a reasonable
          period, as required by law. We retain the right to impose a reasonable
          charge representing the cost incurred in providing the information, or
          to decline Your request where they are incessant or unfounded, in
          which case same will be communicated to You. 
        </p>
      </div>

      <div>
        <p  className=" point-header fw-bolder fs-5">Amendment </p>
        <p className="fw-light">
          This Policy is effective as of date and time it is posted on this
          page. This Policy shall remain in effect except with respect to any
          changes in its provisions in the future, which shall be effective
          immediately after being posted on this page. We reserve the right to
          update or change our Privacy Policy at any time and you should check
          this Policy periodically. Your continued use of the Service after we
          post any modifications to this Policy on this page will constitute
          your acknowledgment of the modifications and your consent to abide by
          and be bound by the modified Privacy Policy. If we make any material
          changes to this Policy, we will notify you either through the email
          address you have provided us or by placing a prominent notice on our
          website.   
        </p>
      </div>
      <div>
        <p  className=" point-header fw-bolder fs-5">Complaints </p>
        <p className="fw-light">
          Your enquiries, comments and requests regarding this Privacy Policy
          may be sent to <a href="mailto:mailto:support@borga.money" className=" fw-bolder">support@borga.money</a>. 
        </p>
      </div>
    </Container>
  );
};

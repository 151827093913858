import React, { useState } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import { Google, Apple, Bg2 } from "../../../assets/images/index";
import Container from "react-bootstrap/Container";
import { Bg1 } from "../../../assets/images/index";

export const EasyPay = () => {
  return (
    <div>
      <Container>
        <div className="on-screen-type-image-display pb-5">{/* . */}</div>
        <Row className="gap-3">
          <Col md={12} lg={6}>
            <div className="" style={{fontSize:"18px"}}>
              <p className="section-text-header lh-sm  mt-2">
                Secure money transfer to Africa at brutaaa rates!
              </p>
              <p className="fw-thin content-text">
                Send money and pay for services such as building materials,
                medical bills, school fees and more directly with
                <span className="fw-bold px-1"> The Borga
                Money App.</span>
              </p>
              <Col className="d-flex brand-logo-container">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=money.borga&hl=en_US&pli=1">
                    <img
                      src={Google}
                      alt="googleplay"
                      className="brand-logo"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>
                <div>
                  <a href="https://apps.apple.com/us/app/borga-money/id6463721868">
                    <img
                      src={Apple}
                      alt="appstore"
                      className="mx-2 brand-logo"
                      style={{ width: "150px" }}
                    />
                  </a>
                </div>
              </Col>
            </div>
          </Col>
          <Col className="alternating-image-section d-none d-md-block py-5"></Col>
          <Col className="d-md-none mt-5">
       
            <Carousel className="custom-carousel">
              <Carousel.Item>
                <img src={Bg1} alt="alt-image" style={{width:"100%", height:"100%", objectFit:"cover" , objectPosition:"top"}} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={Bg2} alt="alt-image" style={{width:"100%", height:"285px", objectFit:"cover" , objectPosition:"top"}} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { EmailVerified } from "./EmailVerified";
import { VerificationExpired } from "./VerificationExpired";
import Lottie from "lottie-react";
import { Loading } from "../../../assets/images";

const BASEURL = `${process.env.REACT_APP_BASE_URL}`;

const Verification = () => {
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState(1);
  const dataFetchedRef = useRef(false);

  async function handleEmailVerificationCheck(queryParameter: any) {
    try {
      setLoading(true);
      const newUrl = BASEURL + "?" + queryParameter;
      const headers = new Headers();

      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      headers.append("X-Content-Type-Options", "nosniff");
      headers.append("X-Frame-Options", "DENY");
      headers.append("Referrer-Policy", "same-origin"); 
      headers.append("Permissions-Policy", "policy-directives");
      headers.append('Cache-Control', 'no-store, max-age=0');

      // const apiResponse = await fetch(newUrl);
      const apiResponse = await fetch(newUrl, {
        method: "GET",
        headers: headers,
      });

      if (apiResponse.status !== 200) {
        setCode(1);
        return;
      }
      const response = await apiResponse.json();
      response.code === 0 ? setCode(response.code) : setCode(1);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const url = window.location.href;
    const splitUrl = url.split("?");
    const queryParameter = splitUrl[1];

    console.log("original url from the backend :: ", url);
    console.log("the query params ", queryParameter);

    handleEmailVerificationCheck(queryParameter);
  });

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Lottie
            animationData={Loading}
            loop={true}
            autoplay={true}
            style={{ width: "200px", height: "300px" }}
          />
        </div>
      ) : (
        <>
          {code === 0 ? (
            <>
              <EmailVerified />
            </>
          ) : (
            <>
              <VerificationExpired />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Verification;


import React, { useState } from "react";
import {  FaqData } from "./faqData"
import Accordion from "react-bootstrap/Accordion";
import { Button,  Col,  Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';

export const FAQ = () => {
    const [showAllFaqs, setShowAllFaqs] = useState(false);
    const [visibleFaqs, setVisibleFaqs] = useState(10);
  
    const toggleVisibleFaqs = () => {
      if (visibleFaqs === 34) {
        setShowAllFaqs(true);
      }
      setVisibleFaqs(visibleFaqs + 4);
    };
  
    const toggleLessFaqs = () => {
      setShowAllFaqs(false);
      setVisibleFaqs(10);
    };
  
    const faqsToShow = showAllFaqs ? FaqData : FaqData.slice(0, visibleFaqs);

  return (
    <section id="faqs">
    <Container className="mb-5">
    <Row className="accordion-section" >
      <div className="section-text-header text-center mb-5">
        Frequently asked questions
      </div>

      <Col md={{ offset: 2, span: 8 }} className="">
        <Accordion defaultActiveKey="0">
          {faqsToShow.map((data, key) => (
            <Accordion.Item eventKey={data.num} key={key}>
              <Accordion.Header>
                <h5 className="point-header">{data.question} </h5>
              </Accordion.Header>
              <Accordion.Body>
                <p className="text-muted">{data.answer}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>
    </Row>
    {FaqData.length > visibleFaqs || FaqData.length > 34 ? (
      <div className="text-center mt-3">
        <Button
          variant="primary"
          className="btn btn-primary"
          onClick={toggleVisibleFaqs}
        >
          Load more
        </Button>
      </div>
    ) : (
      <div className="text-center mt-3">
        <Button
          variant="primary"
          className="btn btn-primary"
          onClick={toggleLessFaqs}
        >
          Show less
        </Button>
      </div>
    )}
    </Container>
  </section>
  )
}

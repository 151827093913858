import React from "react";
import WhatIsBorga from "../what_is_borga/WhatIsBorga";
import { WhatPeopleSayAboutUs } from "../what_people_say_about_us/WhatPeopleSayAboutUs";
import Tutorials from "../tutorials/Tutorials";
import { WhyBorga } from "../why_borga/WhyBorga";
import { EasyPay } from "../easy_payment/EasyPay";
import Merchants from "../merchants/merchants";

const HomePage = () => {
  return (
    <div className="home">
      <section className=" text-black easy-pay-section mb-5 ">
        <EasyPay />
      </section>
      <section className="why-borga-section">
        <WhyBorga />
      </section>
      <section id="what-is-borga" className="what-is-borga-section">
        <WhatIsBorga />
      </section>
      <section className="what-people-say-about-us-section">
        <WhatPeopleSayAboutUs />
      </section>
      <section className="tutorials-section">
        <Tutorials />
      </section>
      <section>
        <Merchants/>
      </section>
    </div>
  );
};

export default HomePage;

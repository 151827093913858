import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function ReferralProgramTerms() {
  return (
    <Container className="my-5 card border-1 px-5 py-5">
      <h1 className="mb-4 text-uppercase section-text-header">Referral Program - Terms and Conditions</h1>

      <Card className="mb-4 border-0">
        <Card.Body>
          <h2 className="point-header">Introduction</h2>
          <p>
            Borga Money (“Borga”) is a remittance company dedicated to facilitating secure and efficient money transfers 
            from the United Kingdom to Ghana through its Borga Money Mobile Application (“the App”).
          </p>
          <p>
            As part of our commitment to our users, Borga offers various programs under its Loyalty Program. Upon signing up, 
            users have the option to enroll in this program, where they receive notifications about ongoing programs and may choose to participate.
          </p>
          <p>
            In line with this commitment, Borga introduces a Referral Program ("Referral Program") to its users. The following 
            Terms and Conditions govern the Referral Program. By participating in the Referral Program, users agree to abide by 
            these Terms and Conditions and the decisions of Borga, which shall be final and binding.
          </p>
        </Card.Body>
      </Card>

      <h2 className="point-header">Eligibility</h2>
      <p>
        Participation in the Referral Program is open to all registered users of the App.
      </p>

      <h2 className="point-header">Referral Codes</h2>
      <p>
        Users can obtain referral codes by accessing the "Settings" section of the app. The steps are below:
      </p>
      <ol>
        <li>Go to Settings.</li>
        <li>Click on Referrals and Promos.</li>
        <li>Accept the Terms and Conditions displayed.</li>
        <li>A unique referral code will appear.</li>
      </ol>
      <p>
        Existing users can use this referral code to invite new users who have not previously signed up on the App. Similarly, 
        new users can also obtain a referral code using a referral code of an existing user. The steps are below:
      </p>
      <ol>
        <li>On the signup page, input email address, phone number, and referral code.</li>
        <li>Accept the Terms and Conditions displayed on the sign-up page.</li>
        <li>Once an account has been created, go to the “Settings” section on the App.</li>
        <li>Click on Referrals and Promo.</li>
        <li>A unique referral code will appear. Users can use this code to invite new users to sign up to the App.</li>
      </ol>

      <h2 className="point-header">No Rewards</h2>
      <p>
        This Referral Program does not offer any rewards or incentives, monetary or otherwise, for sharing or using referral codes.
      </p>

      <h2 className="point-header">Code Validity</h2>
      <p>
        Referral codes are unique to each user and can be used multiple times unless stated otherwise by Borga.
      </p>

      <h2 className="point-header">General Terms</h2>
      <p>
        Borga reserves the right to modify, suspend, or terminate the Referral Program at any time without prior notice. 
        Additionally, Borga may disqualify any user from participating in the Referral Program if they violate these Terms and 
        Conditions or engage in any fraudulent or abusive behavior.
      </p>

      <h2 className="point-header">Contact Information</h2>
      <p>
        If you have any questions or concerns regarding the Referral Program or these Terms and Conditions, please contact us at 
        <a href="mailto:support@borga.money"> support@borga.money</a>.
      </p>

      <p>
        By participating in the Referral Program, users acknowledge that they have read, understood, and agree to be bound by these Terms and Conditions.
      </p>
    </Container>
  );
}

export default ReferralProgramTerms;

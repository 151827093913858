import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

export const WhatPeopleSayAboutUs = () => {
  const feedbacks = [
    {
      icons: "",
      title: "Verify Receiver Details. 🔍👀",
      body: "Always double-check recipient information before sending money. A small confirmation step can save you from big remittance mishaps.",
    },
    {
      icons: "",
      title: "Stay Wary of Suspicious Requests! 🕵‍♂🔍",
      body: "If someone asks for money unexpectedly or urgently, take a moment to investigate. Fraudsters use urgency to catch you off guard, so stay vigilant.",
    },
    {
      icons: "",
      title: "Stay Alert! 🛡🔒",
      body: "Never expose your PIN or passwords to anyone. ",
    },
  ];
  return (
    <Container className="py-4" style={{ backgroundColor: "##1C1C1C" }}>
      <div className="section-text-header text-white py-3 container">
        Borga tips💬
      </div>
      <Swiper
        spaceBetween={35}
        pagination={{ clickable: true }}
        slidesPerView={3}
        navigation={true}
        autoplay={{ delay: 10000, disableOnInteraction: false }}
        modules={[Pagination, Navigation, Autoplay]}
        breakpoints={{
          0: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          375: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          575: {
            spaceBetween: 10,
            slidesPerView: 1,
          },
          767: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
        }}
        className=""
      >
        <Row className="gap-x-5">
          {feedbacks.map((data, key) => (
            <SwiperSlide key={key}>
              <Col className="pb-5 mx-2">
                <Card
                  className="mb-4 mb-lg-0 rounded-4"
                  style={{
                    backgroundColor: "#303030",
                    minHeight: "17rem",
                    height: "auto",
                  }}
                >
                  <Card.Header className="border-0 " style={{height:"100px"}}>
                    <div className="text-white fw-semibold tips-header py-5">
                      {data.title}
                    </div>
                  </Card.Header>
                  <Card.Body className=" p-3">
                    <p
                      className="mb-0 text-white content-text fw-light "
                      style={{ maxHeight: "7rem" }}
                    >
                      {data.body}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </SwiperSlide>
          ))}
        </Row>
      </Swiper>
    </Container>
  );
};

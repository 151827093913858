import React, { useEffect } from "react";
import AppRouting from "./core/routing/AppRouting";
import { BrowserRouter } from "react-router-dom";
import Cookies from 'js-cookie';

function App() {

  useEffect(() => {
    Cookies.set('cookieName', 'cookieValue', { httpOnly: true, secure: true, sameSite: 'strict' });
  }, []);

  return (
    <BrowserRouter>
      <AppRouting />
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import {
  AIMSHOSPITAL,
  DOORMASTER,
  ECO,
  EMAKLINKSOLUTIONS,
  KMCL,
  LENAHOUSECLINIC,
  LENAPHARMACY,
  MEDIFEM,
  MEDITAB,
  PPM,
  ROCKSTERS,
  SONITRA,
  TOPUP,
  PAANII,
  TWUMASIWAAMEDICALCENTRE,
  WORLDVIEWPHARMACY,
  HEALTH,
  TELECEL,
  STARTIME,
  DSTV,
  ECG,
  GO,
} from "../../../assets/images/merchantsLogo";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const merchantLogo = [
  {
    data: [
      {
        title: "Health",
        logos: [
          {
            image: AIMSHOSPITAL,
          },
          { image: KMCL },
          { image: LENAHOUSECLINIC },
          { image: MEDIFEM },
          { image: LENAPHARMACY },
          { image: MEDITAB },
          { image: TOPUP },
          { image: TWUMASIWAAMEDICALCENTRE },
          { image: HEALTH },
          { image: WORLDVIEWPHARMACY },
        ],
      },
      {
        title: "Billers",
        logos: [
          { image: ECG },
          { image: DSTV },
          { image: GO },
          { image: STARTIME },
          { image: TELECEL },
        ],
      },
      {
        title: "Construction",
        logos: [
          { image: DOORMASTER },
          { image: ECO },
          { image: EMAKLINKSOLUTIONS },
          { image: PPM },
          { image: ROCKSTERS },
          { image: SONITRA },
          { image: PAANII },
        ],
      },
    ],
  },
];

const Merchants = () => {
  return (
    <div className="bg-white">
      <Container className="py-5 ">
        <div className="section-text-header mb-4 ">
          Our Merchant Network
        </div>
        <div className="logo-section">
          <Swiper
            spaceBetween={30}
            pagination={{ clickable: true }}
            navigation={true}
            autoplay={{ delay: 10000, disableOnInteraction: false }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {merchantLogo.map((item, index) => (
              <>
                {item.data.map((data, dataIndex) => (
                  <SwiperSlide className="mySwiper">
                    <div className="p-2 d-flex">
                      <div key={dataIndex}>
                        <button className=" merchant-carousel-tab fs-6 d-flex border-0 justify-content-start mb-5 text-white px-5 py-2 bg-primary rounded-pill ">
                          {data.title}
                        </button>
                        <div className="logo-flex">
                          {Array.isArray(data?.logos) &&
                            data?.logos.map((item, key) => (
                              <div
                                key={index}
                                className="p-2 d-flex  merchant-logo-container"
                              >
                                <img
                                  src={item.image}
                                  alt=""
                                  style={{
                                    width: "140px",
                                    height: "100px",
                                    objectFit: "contain",
                                    objectPosition: "center",
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </>
            ))}
          </Swiper>
        </div>
      </Container>
    </div>
  );
};

export default Merchants;

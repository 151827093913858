import React from "react";
import { PageNotFound } from "../../../assets/images";
import { Button, Card,  } from "react-bootstrap";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
export const PageNotFound400 = () => {
  return (
    <section
      className="page-not-found"
    >
      <Container className="d-flex justify-content-center align-items-center"  style={{ minHeight: '100vh' }}>
        <Card  className="d-flex justify-content-center border-0" style={{width:"600px", height:"100%"}}>
          <Card.Img src={PageNotFound} alt="" style={{objectFit:"contain"}} />
          <Card.Body className="text-center">
            <div className="text-center fs-1 fs-bold">Page not Found</div>
            <div className="mb-5">Sorry, the page you are looking for could not be found.
            Please click the button below to go back to the home page.

            </div>
            <div>
            
              <Link to="/home">
                <Button variant="primary" className="px-5 py-3"> <span className="text-info"> Home page </span></Button>
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};
